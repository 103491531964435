const AssetColors: { [key: string]: string } = {
  AUD: '#051091',
  ADA: '#246dd3',
  ADX: '#1b75bc',
  BTC: '#f99e34',
  BAT: '#9f2364',
  BCH: '#4cca47',
  BNB: '#f2ba30',
  ETHOS: '#00ceab',
  VGX: '#2D277F',
  EOS: '#070708',
  ETC: '#669073',
  ETH: '#63698f',
  FUN: '#e02b50',
  IOTA: '#1d2227',
  KMD: '#2a5f5f',
  LSK: '#18507c',
  LTC: '#bebebe',
  MCO: '#021f42',
  NEO: '#74bc2a',
  OMG: '#1a53f0',
  POWR: '#5fd6cb',
  QTUM: '#4bb1e1',
  SALT: '#5799a0',
  SUB: '#ee433a',
  TRX: '#1d2227',
  USDT: '#26a17b',
  USD: '#c53546',
  WAVES: '#0098da',
  WTC: '#8200ff',
  XMR: '#f60',
  XVG: '#01cdfa',
  XRP: '#3e87b3',
  ZEC: '#efb748',
  ZRX: '#1d2227',
  BTT: '#000',
  BTTC: '#000',
  DASH: '#1c75bc',
  DENT: '#1d2227',
  HOT: '#008590',
  LINK: '#2d5fdc',
  MTL: '#000',
  NANO: '#4990e2',
  NPXS: '#ffcc12',
  XLM: '#6e7c7f',
  ZIL: '#298e97',
  SYS: '#0089cf',
  VET: '#22b2f9',
  ONT: '#32a5bf',
  PPT: '#29405f',
  XEM: '#20b5ac',
  BTG: '#e46200',
  USDC: '#2775ca',
  DCR: '#2970ff',
  TUSD: '#002868',
  REP: '#553580',
  BCD: '#f9a428',
  RVN: '#f15b22',
  BTS: '#35baeb',
  ICX: '#1bbdc2',
  PAX: '#005121',
  AE: '#f7296e',
  SC: '#35da70',
  ATOM: '#5064fb',
  STEEM: '#171fc9',
  ENJ: '#4ebae2',
  THETA: '#21ecb9',
  STRAT: '#1382c6',
  SNT: '#5b6dee',
  GNT: '#000',
  ELF: '#2b5eba',
  ARDR: '#1162a1',
  ZEN: '#0e9de5',
  IOST: '#404040',
  NXS: '#187098',
  DOGE: '#bfb271',
  MANA: '#ff4556',
  RLC: '#fbd503',
  XTZ: '#2d7ef7',
  HBAR: '#000000',
  GAS: '#74bc2a',
  ONG: '#32A5BF',
  STX: '#211F6D',
  LEND: '#37B2C3',
  ALGO: '#000000',
  ENG: '#20B1F9',
  WRX: '#3067F0',
  HC: '#56428E',
  BUSD: '#2D598A',
  XZC: '#23B852',
  AION: '#E0DACD',
  AGI: '#6916FF',
  KNC: '#31CB9E',
  RCN: '#3555F9',
  TNT: '#FF4081',
  REN: '#001C3A',
  WABI: '#399B32',
  NKN: '#122D48',
  PERL: '#B4E1FF',
  LTO: '#804BC9',
  FET: '#151A1A',
  BRD: '#FD6E73',
  WIN: '#D1AA10',
  CHZ: '#B02728',
  LRC: '#1C60FF',
  FTT: '#00A7C3',
  COTI: '#3449A8',
  OGN: '#007CFF',
  ARK: '#F70000',
  GRS: '#387E97',
  KAVA: '#FF564F',
  DATA: '#FF5C00',
  CRO: '#081122',
  SNX: '#1e1a31',
  ERD: '#0d022b',
  COMP: '#00d395',
  DGB: '#002352',
  BNT: '#000d2b',
  BAND: '#516aff',
  SXP: '#FF4920',
  DOT: '#E6007A',
  SRM: '#09B9D3',
  OCEAN: '#FF4092',
  SOL: '#D83AEB',
  MFT: '#DA1157',
  FTM: '#1969FF',
  TFUEL: '#FF6810',
  MATIC: '#2B6DEF',
  REQ: '#00E6A0',
  NULS: '#28DC80',
  ONE: '#35D5D2',
  IOTX: '#43C9BA',
  EGLD: '#23F7DD',
  KSM: '#000000',
  YFI: '#025FC0',
  TRB: '#49F5A9',
  MKR: '#1AB696',
  RSR: '#000000',
  PAXG: '#E8E30D',
  UNI: '#F167AD',
  UMA: '#FF4A4A',
  YFII: '#EB4982',
  NMR: '#050708',
  BAL: '#000000',
  ANT: '#01BFDD',
  SUSHI: '#EF6950',
  LUNA: '#E04638',
  LUNC: '#2743A9',
  WNXM: '#257718',
  RUNE: '#18DDC8',
  CRV: '#A5A4CE',
  HIVE: '#DC1235',
  STORJ: '#0055C6',
  JST: '#6124C1',
  IRIS: '#753BF7',
  DIA: '#3619A4',
  TOMO: '#00E8B4',
  WAN: '#0AB6FD',
  BZRX: '#003CDA',
  ANKR: '#006DFF',
  BLZ: '#00579F',
  SAND: '#3BBFF0',
  ARPA: '#263145',
  CELR: '#F2C693',
  VTHO: '#8784DA',
  LOOM: '#29436E',
  CVC: '#3AB03E',
  AST: '#2B71FF',
  CHR: '#FF405D',
  NAS: '#1D0F62',
  DUSK: '#523C98',
  STPT: '#24AAE1',
  PNT: '#222244',
  COCOS: '#2AD9FE',
  FIO: '#419BDE',
  KEY: '#009AB6',
  DREP: '#FBD42C',
  CTSI: '#666666',
  VITE: '#007AFF',
  NAV: '#7B47B4',
  NEBL: '#34ABD1',
  MDT: '#0082FF',
  TCT: '#2862FF',
  TROY: '#86DEFF',
  MBL: '#5B449B',
  OXT: '#5A4D9E',
  AVAX: '#E84242',
  SUN: '#FFD609',
  HNT: '#1B8DFF',
  SCRT: '#000000',
  XVS: '#EABD60',
  ORN: '#64BCD0',
  UTK: '#6932D4',
  AAVE: '#1F9BCB',
  FIL: '#42C1CA',
  INJ: '#1BD1DD',
  NZD: '#061193',
  FLM: '#F763BE',
  WING: '#000000',
  ALPHA: '#28ACF7',
  BEL: '#142551',
  POLY: '#3D487E',
  POLYX: '#EC4673',
  VIDT: '#00BDCA',
  BOT: '#000000',
  NEAR: '#000000',
  DNT: '#2D398F',
  AKRO: '#E19EFD',
  STRAX: '#1382c6',
  GLM: '#000',
  GRT: '#4152BD',
  ROSE: '#107ff2',
  AUDIO: '#ce01cb',
  YOYO: '#007bff',
  GVT: '#00beb0',
  MDA: '#22a650',
  MTH: '#0072ff',
  OAX: '#164b79',
  EVX: '#044aac',
  VIB: '#ff1f43',
  RDN: '#2a2a2a',
  AMB: '#8ae3ff',
  BCPT: '#404040',
  CDT: '#272731',
  QSP: '#454545',
  CMT: '#c1a05c',
  CND: '#000',
  VIBE: '#338be5',
  VIA: '#565656',
  WPR: '#ffe603',
  QLC: '#610089',
  SKY: '#0072ff',
  GO: '#008bcc',
  MITH: '#00316d',
  COS: '#5393cc',
  CTXC: '#000',
  STMX: '#fa2e53',
  AVA: '#2d2d4f',
  WBTC: '#282138',
  AERGO: '#ff33b2',
  CTK: '#e1aa4c',
  HARD: '#4d2176',
  FOR: '#2dc8a1',
  SKL: '#000',
  DLT: '#f4ae95',
  OST: '#34445b',
  PSG: '#0c70ac',
  JUV: '#000',
  POA: '#5b2da7',
  '1INCH': '#1c3351',
  CELO: '#39d188',
  FIRO: '#9B1C2E',
  TWT: '#3171B5',
  GXS: '#2A6AAB',
  TRU: '#1B57F7',
  REEF: '#A40FDC',
  RIF: '#019933',
  AXS: '#0093E0',
  BTCST: '#4B4B4B',
  NBS: '#F6D532',
  SNM: '#0B1B25',
  APPC: '#2C7386',
  IDEX: '#014A68',
  UNFI: '#36F292',
  CAKE: '#D1884F',
  DODO: '#F1E434',
  ACM: '#ea3943',
  FRONT: '#2a1f21',
  AUCTION: '#000',
  BADGER: '#f2a52b',
  OM: '#E41868',
  LINA: '#1425f7',
  RAMP: '#00b5d1',
  PERP: '#3ceaaa',
  LIT: '#04c170',
  TVK: '#6898a4',
  FIS: '#00f3ab',
  PHA: '#d1ff52',
  ALICE: '#EE8196',
  DEGO: '#2d6b6a',
  PUNDIX: '#f9be15',
  EPS: '#4180db',
  SUPER: '#7112ff',
  AUTO: '#4958c5',
  ASR: '#94122d',
  GTO: '#6b0ab8',
  CFX: '#203de4',
  SHIB: '#FF0100',
  TORN: '#44f1a6',
  GTC: '#02e2ac',
  MASK: '#1c68f3',
  MDX: '#02c09f',
  LPT: '#00eb88',
  NU: '#1e65f3',
  POLS: '#ff3565',
  FORTH: '#4440b4',
  ICP: '#3aabe1',
  QUICK: '#2891f9',
  BAR: '#12376c',
  MIR: '#172240',
  TLM: '#020202',
  ERN: '#01e7c1',
  KEEP: '#48dbb4',
  QNT: '#ef4f1f',
  FLOW: '#16ff99',
  CKB: '#59C589',
  AR: '#222326',
  USDP: '#5AC33C',
  VRA: '#ED0A4B',
  MLN: '#6657eb',
  YGG: '#da2c0b',
  SLP: '#FF93A2',
  SFP: '#2f27ff',
  MBOX: '#1b5AF5',
  DEXE: '#7FFFD4',
  BURGER: '#F0B80B',
  BOND: '#FF4339',
  FARM: '#00A1D4',
  TRIBE: '#178DD0',
  CLV: '#22d297',
  ALPACA: '#31C77F',
  TKO: '#1aa64a',
  AGLD: '#1a1a1a',
  BAKE: '#722f0d',
  GNO: '#cccccc',
  GALA: '#272727',
  ILV: '#110f24',
  C98: '#d9b432',
  DYDX: '#121223',
  MINA: '#FF603B',
  RAY: '#c200fb',
  MOVR: '#F2B707',
  OOKI: '#53BAFF',
  PYR: '#ee5817',
  RARE: '#000000',
  RAD: '#5555ff',
  PROM: '#ea7437',
  PLA: '#00d6b3',
  OG: '#012554',
  CITY: '#61a0d1',
  LAZIO: '#85d9f8',
  JASMY: '#f7941c',
  FXS: '#000000',
  ENS: '#5a91ff',
  FIDA: '#7c7cff',
  QI: '#08799e',
  ATM: '#ce3524',
  AMP: '#d62e79',
  GHST: '#511e80',
  SGB: '#D95F6C',
  UST: '#0e3ca5',
  USTC: '#0e3ca5',
  RNDR: '#d00f10',
  IMX: '#16b4cb',
  FLUX: '#2b62d1',
  JOE: '#f35950',
  SPELL: '#7b79f7',
  ALCX: '#F5C09A',
  KLAY: '#fa2d0a',
  T: '#7d01fe',
  XEC: '#00abe7',
  DOCK: '#18181b',
  APE: '#0054F9',
  KDA: '#ED098F',
  DFI: '#FF00AF',
  GMT: '#FF4D00',
  TAUD: '#11975F',
  REI: '#2116E5',
  PEOPLE: '#765D3A',
  EPX: '#8E53E6',
  LDO: '#F69988',
  NEXO: '#1E4DD8',
  OP: '#FF0420',
  LEVER: '#7FDF00',
  OGV: '#183140',
  GAL: '#3671FF',
  POND: '#3840C7',
  GLMR: '#E1147B',
  CVX: '#1682FE',
  BSW: '#1263F1',
  STG: '#000000',
  APT: '#171717',
  HFT: '#23252B',
  HOOK: '#b7fd09',
  MAGIC: '#DC2626',
  FLR: '#E62058',
  HIFI: '#6E2EF4',
  AGIX: '#1B0E48',
  SSV: '#23fbd3',
  XNO: '#209CE9',
  RPL: '#F0A16B',
  FLOKI: '#F79625',
  BABYDOGE: '#E1A756',
  GFT: '#EA4553',
  BLUR: '#FF8700',
  GMX: '#2D42FC',
  ARB: '#213147',
  RDNT: '#5F00FA',
  OSMO: '#462ADF',
  GNS: '#1DE7D9',
  HIGH: '#30A6FC',
  LQTY: '#745DDF',
  PEPE: '#3D8130',
  SUI: '#4CA3FF',
  ID: '#1EEFA4',
  ORDI: '#000000',
  COMBO: '#64D9ED',
  BONE: '#F00500',
  ACH: '#1D5BD2',
  EDU: '#1228F3',
  MAV: '#7210FF',
  WLD: '#000000',
  ARKM: '#000000',
  SEI: '#C1121F',
  CYBER: '#000000',
  PENDLE: '#26447D',
  ASTR: '#001F40',
  WOO: '#20252F',
  NTRN: '#000000',
  BNX: '#F6465D',
  BIGTIME: '#737373',
  TIA: '#7B2BF9',
  MEME: '#E39400',
  VIC: '#000000',
  VANRY: '#03D9AF',
  PYTH: '#7142CF',
  '1000SATS': '#FF8B00',
  BONK: '#FC8E03',
  JTO: '#1D1D1B',
  BEAM: '#FF5500',
  XAI: '#F30919',
  AI: '#F1373A',
  ALT: '#6266DB',
  MANTA: '#29CCB9',
  ACE: '#FDA430',
  JUP: '#00BEF0',
  NFP: '#9970FF',
  SYN: '#D649FF',
  DYM: '#24201F',
  RONIN: '#004DE5',
  METIS: '#00CFFF',
  PIXEL: '#8AD22E',
  API3: '#000000',
  STRK: '#0C0C4F',
  XCH: '#5ECE71',
  CORE: '#FF9211',
  PORTAL: '#0E011E',
  PDA: '#00d6b3',
  AXL: '#0197FE',
  WIF: '#FABC9F',
  KAS: '#3ADDBE',
  BOME: '#609031',
  XDC: '#244B81',
  ENA: '#111111',
  W: '#000000',
  AEVO: '#4B00FF',
  TNSR: '#000000',
  SAGA: '#000000',
  ETHFI: '#6464E4',
  TON: '#0098EA',
  WEN: '#6392C0',
  TAO: '#292929',
  ONDO: '#06070A',
  AKT: '#FF414C',
  NOT: '#000000',
  OMNI: '#0564FF',
  AIOZ: '#1D1D1F',
  MNT: '#000000',
  BRETT: '#00ACDC',
  CSPR: '#FF2D2E',
  TURBO: '#FCCA00',
  PONKE: '#EDB625',
  POPCAT: '#714E48',
  AERO: '#2A54F3',
  IO: '#0D0D0D',
  BB: '#000000',
  DOG: '#FF6600',
  ZK: '#1755F4',
  MERL: '#6335FF',
  MEW: '#EC1C24',
  REZ: '#ACE730',
  ZBU: '#F15729',
  ZRO: '#0A0A0A',
  ZCX: '#01D2B3',
  BLAST: '#FCFC03',
  WEMIX: '#B250FF',
  RATS: '#FF8400',
  MOG: '#083ADB',
  BICO: '#FF4E17',
  SNEK: '#0ACEFF',
  DOP: '#000000',
  ZETA: '#005741',
  PAAL: '#7F56D9',
  PRCL: '#4766EA',
  SLERF: '#F98F04',
  G: '#FFAC43',
  LISTA: '#1E1984',
  SAFE: '#12FF80',
  RSS3: '#1477FB',
  CHEEL: '#6800BA',
  BILLY: '#524034',
  RENDER: '#d00f10',
  ZEUS: '#B283FF',
  FTN: '#A60063',
  PRIME: '#DEF141',
  XRD: '#01C28B',
  BANANA: '#FFCE4F',
  ATH: '#D7FE51',
  TAIKO: '#E91898',
  TRACAI: '#6344DF',
  NEIRO: '#CA506F',
  MPLX: '#000000',
  BSV: '#EAB300',
  COQ: '#E84142',
  GFI: '#CDB148',
  NEON: '#DF42AB',
  NOS: '#10E80C',
  NETMIND: '#FF9A06',
  NAKA: '#F42728',
  DRIFT: '#9162F6',
  NYAN: '#F9F925',
  NEIROETH: '#B6A048',
  DOGS: '#000000',
  SLF: '#6C11FF',
  PHIL: '#0064F9',
  L3: '#101114',
  SUNDOG: '#F75515',
  POL: '#6C00F6',

  // Default
  UNKNOWN: '#5c5c5c',
};

export { AssetColors };
