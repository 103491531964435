import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DonutChart } from '@swyftx/aviary/atoms/Charts/DonutChart';
import { DonutChartItem } from '@swyftx/aviary/atoms/Charts/DonutChart/DonutChart.types';
import { DonutChartLegend } from '@swyftx/aviary/atoms/Charts/DonutChart/DonutChartLegend';
import { useDonutChartMutations } from '@swyftx/aviary/atoms/Charts/DonutChart/hooks/useDonutChartMutations';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { Category } from '@shared/api/@types/categories';

import { observer } from 'mobx-react-lite';
import { useCategory } from 'src/lib/categories/hooks';
import { useCategoryBreakdown } from 'src/lib/categories/hooks/useCategoryBreakdown';

type Props = {
  trendingCategory: Category;
};

const PortfolioCategoryBreakdown: React.FC<Props> = observer(({ trendingCategory }) => {
  const { categoryBreakdown, getCategoryPercentage } = useCategoryBreakdown();
  const { getCategoryById } = useCategory();
  const { t } = useTranslation('assets');
  const { externalMutations, setExternalMutations } = useDonutChartMutations();
  const isXs = useTailwindBreakpoint('xs');
  const chartSize = useMemo(() => (isXs ? 190 : 270), [isXs]);
  const chartInnerRadius = useMemo(() => (isXs ? 2 : 4), [isXs]);

  const mappedData = useMemo((): DonutChartItem[] => {
    const breakdownArray = Array.from(categoryBreakdown.entries()).slice(0, 3);
    const items: DonutChartItem[] = [];
    const otherPercentage = breakdownArray.reduce(
      (prev, [categoryId]) => prev - getCategoryPercentage(categoryId),
      100,
    );

    if (!breakdownArray.length) {
      items.push({
        y: 100,
        color: 'var(--color-background-neutral)',
        key: t('portfolioAnalysis.noAssets'),
        title: t('portfolioAnalysis.noAssets'),
        subTitle: otherPercentage.toString(),
      });
      return items;
    }

    breakdownArray.map(([categoryId]) => {
      const category = getCategoryById(categoryId);
      const percentage = getCategoryPercentage(categoryId);

      items.push({
        y: percentage,
        // eslint-disable-next-line no-restricted-syntax
        color: category?.colour || '',
        key: category?.name || '',
        title: category?.name || '',
        subTitle: percentage.toString(),
        percentage: `${percentage.toString()}%`,
      });
    });

    items.push({
      y: otherPercentage,
      color: 'var(--color-background-neutral)',
      key: t('portfolioAnalysis.otherCategories'),
      title: t('portfolioAnalysis.otherCategories'),
      subTitle: otherPercentage.toString(),
      percentage: `${otherPercentage.toString()}%`,
    });

    return items;
  }, [categoryBreakdown, getCategoryById, getCategoryPercentage, t]);

  return (
    <FlexLayout direction='column' spacing={8}>
      <Heading size='h5'>{t('portfolioAnalysis.breakdown')}</Heading>
      <Body color='secondary' size='xsmall'>
        {t('portfolioAnalysis.breakdownSubTitle')}
      </Body>

      <FlexLayout className='w-11/12' spacing={24}>
        <DonutChart
          id='asset-breakdown'
          className='w-auto'
          innerRadius={chartSize / 2 + chartInnerRadius}
          height={chartSize}
          data={mappedData}
          externalMutations={externalMutations}
          setExternalMutations={setExternalMutations}
        />
        <DonutChartLegend
          chartId='asset-breakdown'
          direction='column'
          color='secondary'
          onUpdateExternalMutations={setExternalMutations}
          data={mappedData}
          showPercentages
        />
      </FlexLayout>

      <Heading size='h5'>{t('portfolioAnalysis.trendingNarrativeTitle')}</Heading>
      <Body color='secondary'>
        {t('portfolioAnalysis.trendingNarrativeContent', { trendingCategory: trendingCategory.name })}
      </Body>
    </FlexLayout>
  );
});

export { PortfolioCategoryBreakdown };
