import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { BuyButton } from '@global-components/BuySellButtons';

import { Category } from '@shared/api/@types/categories';

import { useCategoriesAnalytics } from 'src/lib/categories/hooks/useCategoriesAnalytics';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  trendingCategory: Category;
  modalName: string;
  onBuyBundle?: () => void;
  onLearnMore?: () => void;
};

const CategoryLearnMoreBuyFooter: React.FC<Props> = ({ trendingCategory, modalName, onBuyBundle, onLearnMore }) => {
  const { navigate } = useNavigateRoute();
  const { t } = useTranslation('assets');
  const { getAssetsByCategoryId } = useMarkets();
  const { trackAssetDiscoveryInteraction } = useCategoriesAnalytics();

  const onBuyBundleClicked = useCallback(() => {
    trackAssetDiscoveryInteraction(modalName, 'buyBundle');
    if (onBuyBundle) onBuyBundle();
  }, [modalName, onBuyBundle, trackAssetDiscoveryInteraction]);

  const onLearnMoreClicked = useCallback(() => {
    navigate(NavigationURLs.SingleCategory, { pathParams: { categoryId: trendingCategory.id } });
    trackAssetDiscoveryInteraction(modalName, 'learnMore');
    if (onLearnMore) onLearnMore();
  }, [modalName, navigate, onLearnMore, trackAssetDiscoveryInteraction, trendingCategory.id]);

  return (
    <FlexLayout direction='column' className='sticky bottom-0 bg-color-background-surface-primary pb-16' spacing={24}>
      <Body size='xsmall' color='secondary' className='mt-16'>
        {t('portfolioAnalysis.disclaimer')}
      </Body>

      <FlexLayout alignItems='center' justifyContent='end' spacing={16}>
        <Button variant='outlined' size='lg' className='w-full sm:w-auto' onClick={onLearnMoreClicked}>
          {t('trendingCategory.learnMore')}
        </Button>
        <span className='w-full sm:w-auto'>
          <BuyButton
            assets={getAssetsByCategoryId(trendingCategory.id)}
            color='primary'
            size='lg'
            variant='aviary'
            customLabel={t('trendingCategory.buyBundle')}
            onClickCallback={onBuyBundleClicked}
          />
        </span>
      </FlexLayout>
    </FlexLayout>
  );
};

export { CategoryLearnMoreBuyFooter };
