export enum NavigationURLs {
  /* Base */
  Root = '/',

  /* Asset List */
  Assets = '/assets',
  AssetsAll = '/assets/all',
  AssetsFavourites = '/assets/favourites',
  AssetsGainers = '/assets/gainers',
  AssetsLosers = '/assets/losers',
  AssetsNew = '/assets/new',
  AssetsOwned = '/assets/owned',
  AssetsEarnEligible = '/assets/earn-eligible',
  AssetFilter = '/assets/filter/:filter',
  AssetsCategories = '/assets/categories',
  AssetsCategory = '/assets/category/:categoryId',
  SingleCategory = '/assets/categories/:categoryId',
  SingleAsset = '/assets/:asset',

  /* Aviary Asset List */
  AviaryAssetsAll = '/assets/filter/all',
  AviaryAssetsOwned = '/assets/filter/owned',
  AviaryAssetsFavourites = '/assets/filter/favourites',
  AviaryAssetsGainers = '/assets/filter/gainers',
  AviaryAssetsLosers = '/assets/filter/losers',
  AviaryAssetsNew = '/assets/filter/new',

  /* Bundles */
  Bundles = '/bundles',

  /* Dashboard */
  Dashboard = '/dashboard',

  /* Login */
  Login = '/login',
  Auth = '/auth',

  /* Onboarding */
  Onboarding = '/onboarding',

  /* Source of wealth */
  SourceOfWealth = '/source-of-wealth',

  /* Maintenance */
  Maintenance = '/maintenance',

  /* Message */
  Message = '/message',

  /* Transactions */
  Transactions = '/transactions/:type',
  TransactionsOrders = '/transactions/orders',
  TransactionsDeposits = '/transactions/deposits',
  TransactionsWithdrawals = '/transactions/withdrawals',
  TransactionsRoot = '/transactions/',

  /* Profile */
  ProfileEntityVerification = '/profile/entity-verification',
  ProfileEntityLinkedMembers = '/profile/entity-linked-members',
  ProfileEntitySecurity = '/profile/entity-security',
  ProfileInformation = '/profile/information',
  ProfileSettings = '/profile/settings',
  ProfileSecurity = '/profile/security',
  ProfileVerification = '/profile/verification',
  ProfileTradeFees = '/profile/trade-fees',
  ProfileReferAFriend = '/profile/refer-a-friend',
  ProfileAffiliateProgram = '/profile/affiliate-program',
  ProfileApiKeys = '/profile/api',
  ProfileTaxReports = '/profile/transaction-reports',
  ProfileAbout = '/profile/about',
  Profile = '/profile',

  /* Auto Invest */
  AutoInvest = '/auto-invest',
  AutoInvestCreate = '/auto-invest/create',

  /* Register */
  Register = '/register',

  /* Shop */
  Shop = '/shop',
  LedgerNanoX = '/shop/ledger-nano-x',
  LedgerNanoSPlus = '/shop/ledger-nano-s-plus',

  /* Trade */
  UniversalTradeAsset = '/trade/:asset',
  UniversalTrade = '/trade',

  /* Tutorials */
  Tutorials = '/tutorials',

  /* Quick Buy */
  QuickBuy = '/quick-buy',

  /* Price Alerts */
  PriceAlerts = '/price-alerts',

  /* Wallets */
  SingleWallet = '/wallet/:type/:asset',
  Wallets = '/wallet/:type',
  WalletsRoot = '/wallet',
  WalletsEarn = '/wallet/earn',

  DepositReceive = '#deposit-receive',
  WithdrawSend = '#withdraw-send',

  /* Rewards */
  RewardsRoot = '/rewards',

  /* Portfolio Transfer */
  PortfolioTransfer = '/transfer',

  /* Force Password Reset */
  PasswordReset = '/reset',

  /* Swyftx Pro */
  SwyftxPro = '/pro/trade/:asset',

  /* Investing Entities Onboarding */
  EntityOnboarding = '/entity-application',
}
