import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { AppStore } from '@shared/store';

import { WalletContext } from '@Wallet/Wallet.context';
import {
  WalletList,
  WalletMetrics,
  WalletPortfolioAllocation,
  WalletPortfolioPerformance,
  WalletSelection,
} from '@Wallet/components';
import { typeToWalletType, WalletType } from '@Wallet/types';

import { observer } from 'mobx-react-lite';
import { AssetDiscoverySplitTestModal } from 'src/lib/categories/components';
import { NavigationURLs } from 'src/lib/navigation/types';

type WalletParams = {
  type: 'all' | 'trade' | 'earn';
};

const SHOW_WALLET_SELECTOR = false; // this will only be on if staking comes back

const Wallet: React.FC = observer(() => {
  const { walletType, setWalletType } = useContext(WalletContext);
  const { type } = useParams<WalletParams>();
  const { isDemo } = AppStore.useAppStore;

  const navigate = useNavigate();

  const isValidType = (type: WalletParams | string | undefined): type is WalletParams =>
    type === undefined || type === 'all' || type === 'trade';

  useEffect(() => {
    if (!isValidType(type)) {
      navigate(NavigationURLs.WalletsRoot);
    }
  }, [type, navigate]);

  const updateWalletType = async (newWalletType: WalletType) => {
    setWalletType(newWalletType);
    document.getElementById('app-main-layout')?.scrollTo({ top: 0 });
  };

  useEffect(() => {
    if (type) {
      setWalletType(typeToWalletType[type] as WalletType);
    }
  }, [type, setWalletType]);

  const renderTiles = !isDemo || (isDemo && walletType !== WalletType.EARNING_WALLETS);

  return (
    <>
      <FlexLayout direction='column' className='p-16 @container sm:p-24 sm:pr-0' spacing={16}>
        {SHOW_WALLET_SELECTOR && (
          <FlexLayout direction='row' spacing={16} justifyContent='space-between'>
            <div className='px-16'>
              <WalletSelection onSelect={updateWalletType} walletType={walletType} />
            </div>
          </FlexLayout>
        )}
        {renderTiles && (
          <>
            <FlexLayout direction='column' className='hidden w-full @md:flex'>
              <WalletMetrics walletType={walletType} />
            </FlexLayout>
            <FlexLayout direction='row' spacing={16}>
              <FlexLayout direction='column' className='w-full @md:w-5/12'>
                <Card className='h-full w-full'>
                  <WalletPortfolioAllocation walletType={walletType} />
                </Card>
              </FlexLayout>
              <FlexLayout direction='column' className='hidden w-full @md:flex md:w-7/12'>
                <Card className='h-full w-full'>
                  <WalletPortfolioPerformance walletType={walletType} />
                </Card>
              </FlexLayout>
            </FlexLayout>
          </>
        )}

        <FlexLayout className='min-h-[80px] w-full'>
          <WalletList />
        </FlexLayout>
      </FlexLayout>
      <AssetDiscoverySplitTestModal variant='portfolio' />
    </>
  );
});

export default Wallet;
