import React from 'react';

import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { CategoryPortfolioModal } from './CategoryPortfolioModal';
import { CategoryTrendingModal } from './CategoryTrendingModal';
import { useTrendingCategory } from '../../hooks/useTrendingCategory';

type Props = {
  variant: 'trending' | 'portfolio';
};

const AssetDiscoverySplitTestModal: React.FC<Props> = ({ variant }) => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { trendingCategory } = useTrendingCategory();

  if (isFeatureEnabled(AppFeature.AssetDiscoveryV4SplitTest, 'trending') && variant === 'trending' && trendingCategory)
    return <CategoryTrendingModal defaultOpen trendingCategory={trendingCategory} />;

  if (
    isFeatureEnabled(AppFeature.AssetDiscoveryV4SplitTest, 'portfolio') &&
    variant === 'portfolio' &&
    trendingCategory
  )
    return <CategoryPortfolioModal defaultOpen trendingCategory={trendingCategory} />;

  return null;
};

export { AssetDiscoverySplitTestModal };
