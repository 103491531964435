import { useCallback, useMemo } from 'react';

import { UserBalance } from '@shared/api';
import { Big } from '@shared/safe-big';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { usePortfolioBalance } from 'src/lib/portfolio/hooks/usePortfolioBalance';

import { useCategory } from './useCategory';

const UNCATEGORISED_ID = -1;

const useCategoryBreakdown = () => {
  const { getNoSmallBalances } = usePortfolioBalance();
  const { getAssetById } = useMarkets();
  const { getCategoryById } = useCategory();

  const categoryBreakdown = useMemo(() => {
    const breakdown: Map<number, number> = new Map();

    getNoSmallBalances().forEach((balance: UserBalance) => {
      const asset = getAssetById(balance.assetId);

      if (!asset) return;

      if (!asset?.categoryIds.length) {
        const categoryBreakdownNum = breakdown.get(UNCATEGORISED_ID) || 0;
        breakdown.set(UNCATEGORISED_ID, categoryBreakdownNum + 1);
        return;
      }

      asset?.categoryIds.forEach((assetCategory) => {
        if (!getCategoryById(assetCategory)) return;

        const categoryBreakdownNum = breakdown.get(assetCategory) || 0;
        breakdown.set(assetCategory, categoryBreakdownNum + 1);
      });
    });

    return new Map([...breakdown.entries()].sort((a, b) => b[1] - a[1]));
  }, [getAssetById, getCategoryById, getNoSmallBalances]);

  const getCategoryPercentage = useCallback(
    (categoryId: number) => {
      const total = Array.from(categoryBreakdown).reduce((prev, [, num]) => prev + num, 0);

      return Big(categoryBreakdown.get(categoryId) || 0)
        .div(total)
        .times(100)
        .round(0, 1)
        .toNumber();
    },
    [categoryBreakdown],
  );

  return {
    categoryBreakdown,
    getCategoryPercentage,
  };
};

export { useCategoryBreakdown, UNCATEGORISED_ID };
