import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { Category } from '@shared/api/@types/categories';
import { StorageKey } from '@shared/storage';

import { useLocalStorage } from 'react-use';
import { useCategoriesAnalytics } from 'src/lib/categories/hooks/useCategoriesAnalytics';

import { PortfolioCategoryBreakdown } from './PortfolioCategoryBreakdown';
import { CategoryLearnMoreBuyFooter } from '../CategoryLearnMoreBuyFooter';
import { CategoryTopTradedAssets } from '../CategoryTopTradedAssets';

type Props = {
  defaultOpen?: boolean;
  trendingCategory: Category;
};

const CategoryPortfolioModal: React.FC<Props> = ({ trendingCategory, defaultOpen = false }) => {
  const [open, setOpen] = useState<boolean>(defaultOpen);
  const [seenModal, setSeenModal] = useLocalStorage<boolean>(StorageKey.SEEN_PORTFOLIO_AD4_MODAL, false);
  const isXs = useTailwindBreakpoint('xs');
  const { t } = useTranslation('assets');
  const { trackAssetDiscoveryImpression, trackAssetDiscoveryInteraction } = useCategoriesAnalytics();

  const onOpenChange = useCallback(
    (o?: boolean, trackAnalytics = true) => {
      setOpen(!!o);
      if (!o) {
        setSeenModal(true);
        if (trackAnalytics) trackAssetDiscoveryInteraction('portfolio', 'close');
      }
    },
    [setSeenModal, trackAssetDiscoveryInteraction],
  );

  useEffect(() => {
    if (open && !seenModal) {
      trackAssetDiscoveryImpression('portfolio');
    }
  }, [open, seenModal, trackAssetDiscoveryImpression]);

  return (
    <Modal
      title={t('portfolioAnalysis.title')}
      open={open && !seenModal}
      onOpenChange={onOpenChange}
      position={isXs ? 'bottom' : 'center'}
      className='sm:min-w-[576px] md:min-w-[740px] lg:min-w-[800px] lg:max-w-[840px]'
    >
      <FlexLayout direction='column' className='px-16 pb-16 md:px-24 md:pb-24' spacing={16}>
        <Body color='secondary'>{t('portfolioAnalysis.subTitle')}</Body>
        <FlexLayout className='flex-col md:flex-row' spacing={24} justifyContent='space-between'>
          <PortfolioCategoryBreakdown trendingCategory={trendingCategory} />
          <div className='hidden w-1 bg-color-border-main sm:block' />
          <CategoryTopTradedAssets onSelectAsset={() => onOpenChange(false, false)} modalName='portfolio' />
        </FlexLayout>

        <CategoryLearnMoreBuyFooter
          modalName='portfolio'
          trendingCategory={trendingCategory}
          onBuyBundle={() => onOpenChange(false, false)}
          onLearnMore={() => onOpenChange(false, false)}
        />
      </FlexLayout>
    </Modal>
  );
};

export { CategoryPortfolioModal };
