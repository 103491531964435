import React, { useCallback, useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ListItem } from '@swyftx/aviary/atoms/List';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { UniversalTradeStore } from '@shared/store';
import { TradeAssetAction, TradeSide } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { useCategoriesAnalytics } from 'src/lib/categories/hooks/useCategoriesAnalytics';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  modalName: string;
  assetCode: string;
  onSelectAsset?: () => void;
};

const CategoryTopTradedAsset: React.FC<Props> = ({ modalName, assetCode, onSelectAsset }) => {
  const { getAssetByCode } = useMarkets();
  const { navigate } = useNavigateRoute();
  const { trackAssetDiscoveryInteraction } = useCategoriesAnalytics();
  const { setTradeAssets } = UniversalTradeStore;

  const baseAsset = useBaseAsset();
  const asset = useMemo(() => getAssetByCode(assetCode), [assetCode, getAssetByCode]);

  const onAssetClicked = useCallback(() => {
    navigate(NavigationURLs.UniversalTradeAsset, { pathParams: { asset: assetCode } });
    trackAssetDiscoveryInteraction(modalName, assetCode);

    if (baseAsset && asset) setTradeAssets([asset.id], TradeSide.To, TradeAssetAction.Replace, [baseAsset.id]);

    if (onSelectAsset) onSelectAsset();
  }, [asset, assetCode, baseAsset, modalName, navigate, onSelectAsset, setTradeAssets, trackAssetDiscoveryInteraction]);

  if (!asset) return null;

  return (
    <>
      <ListItem onClick={onAssetClicked} className='w-full'>
        <FlexLayout spacing={12} justifyContent='space-between' alignItems='center' className='w-full'>
          <FlexLayout spacing={12} justifyContent='start' alignItems='center' className='w-full'>
            <AssetIcon asset={asset} size={24} />
            <FlexLayout direction='column' alignItems='start'>
              <Body size='small' weight='emphasis' className='w-full truncate text-start md:w-[120px]'>
                {asset.name}
              </Body>
              <Body size='xsmall' color='secondary'>
                {asset.code}
              </Body>
            </FlexLayout>
          </FlexLayout>
          <NumericDataItem data={`${asset.priceChange.week.toFixed(2)}%`} size='medium' weight='bold' />
        </FlexLayout>
      </ListItem>
      <Separator className='block h-1 w-full md:hidden' />
    </>
  );
};

export { CategoryTopTradedAsset };
