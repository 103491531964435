import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Button, Stack } from '@swyftx/react-web-design-system';

import { MobileVerificationModal } from '@global-components/Modals/SendCrypto/MobileVerificationModal';
import { SourceOfWealthRequiredNotification } from '@global-components/SourceOfWealthNotification/SourceOfWealthRequiredNotification';
import { WithdrawSendDetails } from '@global-components/WithdrawSendDetails';
import { useWithdrawFunds } from '@global-components/WithdrawSendDetails/WithdrawSendDetails.utils';
import { InformationMessageBox } from '@global-components/message-boxes/InformationMessageBox';

import { api } from '@shared/api';
import { Asset, AssetType } from '@shared/api/@types/markets';
import { WithdrawalAddress } from '@shared/services';
import { UIStore, UserStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useTransferAssets } from '@hooks/Assets/useTransferAssets';
import { useWithdrawalPermission } from '@hooks/Withdraw/useWithdrawalPermission';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { TransferAddressModal } from './TransferAddressModal/TransferAddressModal';
import { TransferWithdrawFees } from './components/TransferWithdrawSendFees';
import { TransferEnum } from '../../TransferModal.types';
import { TransferModalAssetPicker } from '../../components/TransferModalAssetPicker';

type Props = {
  initialAsset?: Asset;
  setOnAction: (action: () => void) => void;
  setDisabled: (disabled: boolean) => void;
  setHideContinue?: (hide: boolean) => void;
  onClose: () => void;
  onReceive: () => void;
};

const generateKey = () => Date.now().toString(36) + Math.random().toString(36).substring(2);

const MAX_ATTEMPTS_PER_CODE = 3;

export const TransferWithdrawSend: React.FC<Props> = observer(
  ({ initialAsset, setOnAction, setDisabled, onClose, onReceive, setHideContinue }) => {
    const { t } = useTranslation('modals', { keyPrefix: 'transferModal.withdraw' });
    const { addMessageBox } = UIStore.useUIStore;
    const { assets } = useTransferAssets(TransferEnum.WithdrawSend);

    const { isKyc2Required } = UserStore.useUserStore;
    const { isFeatureEnabled } = useIsFeatureEnabled();
    const sourceOfWealthRequired = isFeatureEnabled(AppFeature.SourceOfWealth) && isKyc2Required();

    const [withdrawAddress, setWithdrawAddress] = useState<WithdrawalAddress | undefined>();
    const [withdrawAmount, setWithdrawAmount] = useState<string>('');
    const [withdrawReason, setWithdrawReason] = useState<any>('');
    const [error, setError] = useState('');

    const [showAddressModal, setShowAddressModal] = useState<boolean>(false);
    const [selectedAsset, setSelectedAsset] = useState<Asset | null>(initialAsset || null);
    // Used to determine whether to trigger an address fetch on the dropdown
    const [addressStateKey, setAddressStateKey] = useState<string>(generateKey());

    const [showPhoneVerificationStep, setShowPhoneVerification] = useState(false);
    const [withdrawResponseToken, setWithdrawResponseToken] = useState<string | undefined>('');
    const [updatedNameOnAccount, setUpdatedNameOnAccount] = useState<string | undefined>('');
    const [pin, setPin] = useState<string>('');

    const { withdrawFunds } = useWithdrawFunds(selectedAsset, withdrawAmount, withdrawReason, withdrawAddress);

    const baseAsset = useBaseAsset();
    const countryAsset = useCountryAsset();

    const isFiat = selectedAsset?.assetType === AssetType.Fiat;

    const withdrawalBlockedStatus = useWithdrawalPermission(selectedAsset?.code);
    const withdrawalsBlocked = withdrawalBlockedStatus.blocked;

    const hasActiveNetworks = useMemo(() => {
      if (!selectedAsset || selectedAsset.assetType === AssetType.Fiat) return true;

      return !selectedAsset.networks.every((network) => network.withdrawDisableForce || network.withdrawDisabled);
    }, [selectedAsset]);

    const requiresUpdatedNameOnAccount = useMemo(
      () => selectedAsset?.code === 'AUD' && withdrawAddress && !withdrawAddress?.address_details.nameOnAccount,
      [selectedAsset?.code, withdrawAddress],
    );

    const isDirty = useMemo(() => {
      if (!selectedAsset) return false;
      if (!withdrawAmount) return false;
      if (!withdrawReason) return false;
      if (!withdrawAddress) return false;
      if (requiresUpdatedNameOnAccount && (!updatedNameOnAccount || updatedNameOnAccount.length < 2)) return false;

      return true;
    }, [
      requiresUpdatedNameOnAccount,
      selectedAsset,
      updatedNameOnAccount,
      withdrawAddress,
      withdrawAmount,
      withdrawReason,
    ]);

    const mobileVerificationSubmitLabel = (() => {
      if (selectedAsset) {
        if (isFiat) {
          return t('mobileVerificationSubmitFiat', { code: selectedAsset.code });
        }
        return t('mobileVerificationSubmitCrypto', { code: selectedAsset.code });
      }
      return undefined;
    })();

    const setInfoBox = useCallback(
      (token?: string) => {
        addMessageBox({
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
          content: <InformationMessageBox title={t('smsSent')} />,
        });
        setWithdrawResponseToken(token);
        setShowPhoneVerification(true);
      },
      [addMessageBox, t],
    );

    const openAddressModal = () => {
      setShowAddressModal(true);
    };

    const generateNewAddressState = useCallback(() => {
      setAddressStateKey(generateKey());
    }, []);

    const closeAddressModal = () => {
      setShowAddressModal(false);
      generateNewAddressState();
    };

    const doWithdrawal = async () => {
      const auth = withdrawResponseToken && pin ? `${withdrawResponseToken} ${pin}` : undefined;
      await withdrawFunds(auth);
      onClose();
    };

    const resendCode = async () => {
      setWithdrawResponseToken('');
      setPin('');

      const response = await withdrawFunds();
      setWithdrawResponseToken(response.token);
    };

    const closeMobileVerification = () => {
      setShowPhoneVerification(false);
      setWithdrawResponseToken('');
      setPin('');
    };

    const updateNameOnAccount = useCallback(
      (code: string, addressId: number, nameOnAccount: string) =>
        api.endpoints.updateWithdrawAddress({
          params: {
            code,
          },
          data: {
            address: {
              id: addressId,
              addressDetails: {
                nameOnAccount,
              },
            },
          },
        }),
      [],
    );

    useEffect(() => {
      if (setHideContinue) {
        if (selectedAsset) {
          setHideContinue(false);
        } else {
          setHideContinue(true);
        }
      }
    }, [initialAsset, selectedAsset, setHideContinue]);

    useEffect(() => {
      setWithdrawAmount('');
      setWithdrawReason('');
      setWithdrawAddress(undefined);
    }, [selectedAsset]);

    useEffect(() => {
      if (error.length < 1 && isDirty) {
        setOnAction(() => async () => {
          try {
            setDisabled(true);

            // If we are transferring AUD but there is no name on the account, update the details first
            if (
              selectedAsset?.code === 'AUD' &&
              withdrawAddress &&
              !withdrawAddress?.address_details.nameOnAccount &&
              updatedNameOnAccount
            ) {
              await updateNameOnAccount(selectedAsset.code, withdrawAddress.id, updatedNameOnAccount);
            }

            const response = await withdrawFunds();
            if (response.smsVerificationRequired) {
              setInfoBox(response.token);
            } else {
              onClose();
            }
          } catch (ex) {
            // Todo
          } finally {
            setDisabled(false);
          }
        });
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }, [error, setOnAction, setDisabled, withdrawFunds, onClose, setInfoBox, isDirty, updatedNameOnAccount]);

    if (!baseAsset || !countryAsset) return null;

    return (
      <>
        {showPhoneVerificationStep && (
          <MobileVerificationModal
            overrideSubmitLabel={mobileVerificationSubmitLabel}
            maxAttempts={MAX_ATTEMPTS_PER_CODE}
            onClose={closeMobileVerification}
            onResendCode={resendCode}
            onSubmit={doWithdrawal}
            setCode={setPin}
            code={pin}
          />
        )}

        <Stack spacing={3}>
          <TransferModalAssetPicker
            setSelectedAsset={setSelectedAsset}
            id='withdraw-asset-picker'
            assets={assets}
            initialAsset={initialAsset}
            direction='withdraw'
          />

          {selectedAsset && (
            <Stack spacing={2}>
              <FlexLayout direction='column' spacing={12}>
                {sourceOfWealthRequired && <SourceOfWealthRequiredNotification action={onClose} />}
                {isFiat && (
                  <Notification severity='info' title={t('info.title')}>
                    {t('info.message')}
                  </Notification>
                )}
              </FlexLayout>

              {hasActiveNetworks && (
                <>
                  <WithdrawSendDetails
                    withdrawDisabled={withdrawalsBlocked}
                    loading={withdrawalBlockedStatus.loading}
                    setWithdrawAddress={setWithdrawAddress}
                    setWithdrawAmount={setWithdrawAmount}
                    setWithdrawReason={setWithdrawReason}
                    withdrawAddress={withdrawAddress}
                    addressStateKey={addressStateKey}
                    withdrawAmount={withdrawAmount}
                    withdrawReason={withdrawReason}
                    onAddAddress={openAddressModal}
                    onClose={onClose}
                    onReceive={onReceive}
                    countryAsset={countryAsset}
                    baseAsset={baseAsset}
                    asset={selectedAsset}
                    setError={setError}
                    updatedNameOnAccount={updatedNameOnAccount}
                    setUpdatedNameOnAccount={setUpdatedNameOnAccount}
                    error={error}
                  />
                  <TransferWithdrawFees
                    selectedAsset={selectedAsset}
                    withdrawAmount={withdrawAmount}
                    withdrawAddress={withdrawAddress}
                  />
                </>
              )}
            </Stack>
          )}

          {withdrawalsBlocked && (
            <Notification
              severity='destructive'
              title={selectedAsset ? `Withdrawals blocked for ${selectedAsset.name}` : 'Withdrawals blocked'}
              actions={
                <Button color='error' sx={{ marginLeft: '-8px' }} className='intercom-trigger-bot-withdrawals-blocked'>
                  Contact support
                </Button>
              }
            >
              {withdrawalBlockedStatus.message}
            </Notification>
          )}

          {!withdrawalsBlocked && !hasActiveNetworks && (
            <Notification severity='destructive' title='Withdrawals are currently disabled'>
              {`Withdrawals are temporarily suspended for ${selectedAsset?.name}`}
            </Notification>
          )}
        </Stack>

        <TransferAddressModal
          refetchAddresses={generateNewAddressState}
          onClose={closeAddressModal}
          show={showAddressModal}
          asset={selectedAsset}
        />
      </>
    );
  },
);
