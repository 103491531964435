import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { CategoryTopTradedAsset } from './CategoryTopTradedAsset';

type Props = {
  modalName: string;
  onSelectAsset?: () => void;
};

const TOP_TRADED_ASSETS = ['RENDER', 'FET', 'TURBO', 'AI', 'KEY'];

const CategoryTopTradedAssets: React.FC<Props> = ({ modalName, onSelectAsset }) => {
  const { t } = useTranslation('assets');

  return (
    <FlexLayout direction='column' spacing={8} className='w-full md:w-1/3'>
      <Heading size='h5'>{t('trendingCategory.topTradedTitle')}</Heading>
      <Body color='secondary' size='small'>
        {t('trendingCategory.topTradedSubTitle')}
      </Body>
      {TOP_TRADED_ASSETS.map((assetCode) => (
        <CategoryTopTradedAsset
          assetCode={assetCode}
          modalName={modalName}
          key={assetCode}
          onSelectAsset={onSelectAsset}
        />
      ))}
    </FlexLayout>
  );
};

export { CategoryTopTradedAssets };
